import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

const App = () => (
  <SwaggerUI
    spec={{
      "openapi": "3.0.2",
      "info": {
        "title": "Abbonamento Musei API",
        "description":
          "Le API di Abbonamento Musei consentono di esplorare i dati messi a disposizione da Abbonamento Musei su Eventi, Notizie, Mostre per le regioni Piemonte, Lombardia e Valle d'Aosta\n\n\nATTENZIONE: Nel caso in cui l'attributo 'orari' o 'aperture_chiusure_straordinarie' di oggetti di tipo Evento o Mostre non sia presente dovranno essere considerati, ove presenti, i corrispondenti attributi dell'oggetto SpazioEspositivo contenuti negli oggetti stessi",
        "version": "0.5",
      },
      "servers": [
        {
          "url": "https://abbonamento-musei.b4a.app",
        },
      ],
      "tags": [
        {
          "name": "notizie",
        },
      ],
      "paths": {
        "/notizie/{id}": {
          "get": {
            "tags": ["notizie"],
            "description": "Restituisce i dettagli della notizia",
            "security": [
              {
                "ApiKeyAuth": [],
              },
            ],
            "parameters": [
              {
                "name": "id",
                "schema": {
                  "type": "integer",
                },
                "in": "path",
                "required": true,
                "description": "WP IDENTIFIER",
              },
            ],
            "responses": {
              "200": {
                "$ref": "#/components/schemas/Notizia",
              },
              "400": {
                "$ref": "#/components/responses/BadRequest",
              },
              "401": {
                "$ref": "#/components/responses/Unauthorized",
              },
              "404": {
                "$ref": "#/components/responses/NotFound",
              },
              "408": {
                "$ref": "#/components/responses/RequestTimeout",
              },
              "500": {
                "$ref": "#/components/responses/InternalServerError",
              },
              "502": {
                "$ref": "#/components/responses/ServiceUnavailable",
              },
            },
          },
        },
        "/eventi/{id}": {
          "get": {
            "tags": ["eventi"],
            "description": "Restituisce i dettagli dell'evento",
            "security": [
              {
                "ApiKeyAuth": [],
              },
            ],
            "parameters": [
              {
                "name": "id",
                "schema": {
                  "type": "integer",
                },
                "in": "path",
                "required": true,
                "description": "WP IDENTIFIER",
              },
            ],
            "responses": {
              "200": {
                "$ref": "#/components/schemas/Evento",
              },
              "400": {
                "$ref": "#/components/responses/BadRequest",
              },
              "401": {
                "$ref": "#/components/responses/Unauthorized",
              },
              "404": {
                "$ref": "#/components/responses/NotFound",
              },
              "408": {
                "$ref": "#/components/responses/RequestTimeout",
              },
              "500": {
                "$ref": "#/components/responses/InternalServerError",
              },
              "502": {
                "$ref": "#/components/responses/ServiceUnavailable",
              },
            },
          },
        },
        "/eventi": {
          "get": {
            "tags": ["eventi"],
            "description":
              "Restituisce l'elenco paginato degli eventi futuri, ovvero dalla data della richiesta, appartenenti all'iniziativa bgbs23, ordinandoli per 'data_evento'.",
            "security": [
              {
                "ApiKeyAuth": [],
              },
            ],
            "parameters": [
              {
                "name": "size",
                "in": "query",
                "required": false,
                "style": "form",
                "explode": true,
                "schema": {
                  "type": "string",
                },
                "example": "10",
              },
              {
                "name": "page",
                "in": "query",
                "required": false,
                "style": "form",
                "explode": true,
                "schema": {
                  "type": "string",
                },
                "example": "0",
              },
            ],
            "responses": {
              "200": {
                "description":
                  "Restituisce il numero totale e la pagina degli eventi futuri, ovvero dalla data della richiesta, appartenenti all'iniziativa bgbs23, ordinandoli per 'data_evento'.",
                "content": {
                  "application/json": {
                    "schema": {
                      "type": "object",
                      "properties": {
                        "count": {
                          "type": "number",
                          "format": "int32",
                        },
                        "results": {
                          "$ref": "#/components/schemas/ArrayOfEvento",
                        },
                      },
                    },
                  },
                },
              },
              "400": {
                "$ref": "#/components/responses/BadRequest",
              },
              "401": {
                "$ref": "#/components/responses/Unauthorized",
              },
              "408": {
                "$ref": "#/components/responses/RequestTimeout",
              },
              "500": {
                "$ref": "#/components/responses/InternalServerError",
              },
              "502": {
                "$ref": "#/components/responses/ServiceUnavailable",
              },
            },
            "servers": [
              {
                "url": "https://abbonamento-musei.b4a.app",
              },
            ],
          },
          "servers": [
            {
              "url": "https://abbonamento-musei.b4a.app",
            },
          ],
        },
        "/mostre": {
          "get": {
            "tags": ["mostre"],
            "description": "elenco paginato delle mostre - ",
            "security": [
              {
                "ApiKeyAuth": [],
              },
            ],
            "parameters": [
              {
                "name": "size",
                "in": "query",
                "required": false,
                "style": "form",
                "explode": true,
                "schema": {
                  "type": "string",
                },
                "example": "10",
              },
              {
                "name": "page",
                "in": "query",
                "required": false,
                "style": "form",
                "explode": true,
                "schema": {
                  "type": "string",
                },
                "example": "0",
              },
            ],
            "responses": {
              "200": {
                "description":
                  "Restituisce il numero totale e la pagina delle mostre temporanee future, rispetto alla data della richiesta, che appartengono all'iniziativa bgbs23, ordinate per 'data_inizio'",
                "content": {
                  "application/json": {
                    "schema": {
                      "type": "object",
                      "properties": {
                        "count": {
                          "type": "number",
                          "format": "int32",
                        },
                        "results": {
                          "$ref": "#/components/schemas/ArrayOfMostra",
                        },
                      },
                    },
                  },
                },
              },
              "400": {
                "$ref": "#/components/responses/BadRequest",
              },
              "401": {
                "$ref": "#/components/responses/Unauthorized",
              },
              "408": {
                "$ref": "#/components/responses/RequestTimeout",
              },
              "500": {
                "$ref": "#/components/responses/InternalServerError",
              },
              "502": {
                "$ref": "#/components/responses/ServiceUnavailable",
              },
            },
          },
        },
        "/notizie": {
          "get": {
            "tags": ["notizie"],
            "description":
              "Restituisce l'elenco paginato delle notizie in ordine decrescente rispetto alla data di pubblicazione, ovvero wp_date",
            "security": [
              {
                "ApiKeyAuth": [],
              },
            ],
            "parameters": [
              {
                "name": "size",
                "in": "query",
                "required": false,
                "style": "form",
                "explode": true,
                "schema": {
                  "type": "string",
                },
                "example": "10",
              },
              {
                "name": "page",
                "in": "query",
                "required": false,
                "style": "form",
                "explode": true,
                "schema": {
                  "type": "string",
                },
                "example": "0",
              },
            ],
            "responses": {
              "200": {
                "description":
                  "Restituisce il numero totale e la pagina delle notizie future, ",
                "content": {
                  "application/json": {
                    "schema": {
                      "type": "object",
                      "properties": {
                        "count": {
                          "type": "number",
                          "format": "int32",
                        },
                        "results": {
                          "$ref": "#/components/schemas/ArrayOfNotizia",
                        },
                      },
                    },
                  },
                },
              },
              "400": {
                "$ref": "#/components/responses/BadRequest",
              },
              "401": {
                "$ref": "#/components/responses/Unauthorized",
              },
              "408": {
                "$ref": "#/components/responses/RequestTimeout",
              },
              "500": {
                "$ref": "#/components/responses/InternalServerError",
              },
              "502": {
                "$ref": "#/components/responses/ServiceUnavailable",
              },
            },
          },
        },
        "/mostre/{id}": {
          "get": {
            "tags": ["mostre"],
            "description": "Restituisce il dettaglio della mostra",
            "security": [
              {
                "ApiKeyAuth": [],
              },
            ],
            "parameters": [
              {
                "name": "id",
                "schema": {
                  "type": "integer",
                },
                "in": "path",
                "required": true,
                "description": "WP IDENTIFIER",
              },
            ],
            "responses": {
              "200": {
                "$ref": "#/components/schemas/Mostra",
              },
              "400": {
                "$ref": "#/components/responses/BadRequest",
              },
              "401": {
                "$ref": "#/components/responses/Unauthorized",
              },
              "404": {
                "$ref": "#/components/responses/NotFound",
              },
              "408": {
                "$ref": "#/components/responses/RequestTimeout",
              },
              "500": {
                "$ref": "#/components/responses/InternalServerError",
              },
              "502": {
                "$ref": "#/components/responses/ServiceUnavailable",
              },
            },
          },
        },
        "/spazi_espositivi": {
          "get": {
            "tags": ["spazioEspositivo"],
            "description":
              "elenco paginato degli spazi espositivi - Ordinati alfabeticamente, conterranno l'eventuale mostra permanente ",
            "security": [
              {
                "ApiKeyAuth": [],
              },
            ],
            "parameters": [
              {
                "name": "size",
                "in": "query",
                "required": false,
                "style": "form",
                "explode": true,
                "schema": {
                  "type": "string",
                },
                "example": "10",
              },
              {
                "name": "page",
                "in": "query",
                "required": false,
                "style": "form",
                "explode": true,
                "schema": {
                  "type": "string",
                },
                "example": "0",
              },
            ],
            "responses": {
              "200": {
                "description":
                  "elenco paginato degli spazi espositivi - Ordinati alfabeticamente, conterranno l'eventuale mostra permanente ",
                "content": {
                  "application/json": {
                    "schema": {
                      "type": "object",
                      "properties": {
                        "count": {
                          "type": "number",
                          "format": "int32",
                        },
                        "results": {
                          "$ref":
                            "#/components/schemas/ArrayOfSpazioEspositivo",
                        },
                      },
                    },
                  },
                },
              },
              "400": {
                "$ref": "#/components/responses/BadRequest",
              },
              "401": {
                "$ref": "#/components/responses/Unauthorized",
              },
              "408": {
                "$ref": "#/components/responses/RequestTimeout",
              },
              "500": {
                "$ref": "#/components/responses/InternalServerError",
              },
              "502": {
                "$ref": "#/components/responses/ServiceUnavailable",
              },
            },
          },
        },
        "/spazi_espositivi/{id}": {
          "get": {
            "tags": ["spazioEspositivo"],
            "description": "Restituisce il dettaglio dello Spazio Espositivo",
            "security": [
              {
                "ApiKeyAuth": [],
              },
            ],
            "parameters": [
              {
                "name": "id",
                "schema": {
                  "type": "integer",
                },
                "in": "path",
                "required": true,
                "description": "WP IDENTIFIER",
              },
            ],
            "responses": {
              "200": {
                "$ref": "#/components/schemas/SpazioEspositivo",
              },
              "400": {
                "$ref": "#/components/responses/BadRequest",
              },
              "401": {
                "$ref": "#/components/responses/Unauthorized",
              },
              "404": {
                "$ref": "#/components/responses/NotFound",
              },
              "408": {
                "$ref": "#/components/responses/RequestTimeout",
              },
              "500": {
                "$ref": "#/components/responses/InternalServerError",
              },
              "502": {
                "$ref": "#/components/responses/ServiceUnavailable",
              },
            },
          },
        },
        "/luoghi": {
          "get": {
            "tags": ["luogoSpettacolo"],
            "description":
              "elenco paginato dei luoghi di spettacolo - Ordinati alfabeticamente ",
            "security": [
              {
                "ApiKeyAuth": [],
              },
            ],
            "parameters": [
              {
                "name": "size",
                "in": "query",
                "required": false,
                "style": "form",
                "explode": true,
                "schema": {
                  "type": "string",
                },
                "example": "10",
              },
              {
                "name": "page",
                "in": "query",
                "required": false,
                "style": "form",
                "explode": true,
                "schema": {
                  "type": "string",
                },
                "example": "0",
              },
            ],
            "responses": {
              "200": {
                "description":
                  "elenco paginato dei luoghi di spettacolo- Ordinati alfabeticamente ",
                "content": {
                  "application/json": {
                    "schema": {
                      "type": "object",
                      "properties": {
                        "count": {
                          "type": "number",
                          "format": "int32",
                        },
                        "results": {
                          "$ref": "#/components/schemas/ArrayOfLuogoSpettacolo",
                        },
                      },
                    },
                  },
                },
              },
              "400": {
                "$ref": "#/components/responses/BadRequest",
              },
              "401": {
                "$ref": "#/components/responses/Unauthorized",
              },
              "408": {
                "$ref": "#/components/responses/RequestTimeout",
              },
              "500": {
                "$ref": "#/components/responses/InternalServerError",
              },
              "502": {
                "$ref": "#/components/responses/ServiceUnavailable",
              },
            },
          },
        },
        "/luoghi/{id}": {
          "get": {
            "tags": ["luogoSpettacolo"],
            "description": "Restituisce il dettaglio del Luogo di Spettacolo",
            "security": [
              {
                "ApiKeyAuth": [],
              },
            ],
            "parameters": [
              {
                "name": "id",
                "schema": {
                  "type": "integer",
                },
                "in": "path",
                "required": true,
                "description": "WP IDENTIFIER",
              },
            ],
            "responses": {
              "200": {
                "$ref": "#/components/schemas/LuogoSpettacolo",
              },
              "400": {
                "$ref": "#/components/responses/BadRequest",
              },
              "401": {
                "$ref": "#/components/responses/Unauthorized",
              },
              "404": {
                "$ref": "#/components/responses/NotFound",
              },
              "408": {
                "$ref": "#/components/responses/RequestTimeout",
              },
              "500": {
                "$ref": "#/components/responses/InternalServerError",
              },
              "502": {
                "$ref": "#/components/responses/ServiceUnavailable",
              },
            },
          },
        },
        "/categories": {
          "get": {
            "tags": ["categoria"],
            "description":
              "elenco delle categorie di luoghi e spazi espositivi",
            "security": [
              {
                "ApiKeyAuth": [],
              },
            ],

            "responses": {
              "200": {
                "description":
                  "elenco delle categorie di luoghi e spazi espositivi - Ordinati alfabeticamente,",
                "content": {
                  "application/json": {
                    "schema": {
                      "$ref": "#/components/schemas/ArrayOfCategories",
                    },
                  },
                },
              },
              "400": {
                "$ref": "#/components/responses/BadRequest",
              },
              "401": {
                "$ref": "#/components/responses/Unauthorized",
              },
              "408": {
                "$ref": "#/components/responses/RequestTimeout",
              },
              "500": {
                "$ref": "#/components/responses/InternalServerError",
              },
              "502": {
                "$ref": "#/components/responses/ServiceUnavailable",
              },
            },
          },
        },
      },
      "components": {
        "securitySchemes": {
          "ApiKeyAuth": {
            "type": "apiKey",
            "in": "header",
            "name": "X-Access-Token",
          },
        },
        "schemas": {
          "Notizia": {
            "type": "object",
            "description": "Success",
            "properties": {
              "wp_id": {
                "type": "integer",
                "format": "int64",
              },
              "wp_modified": {
                "type": "string",
                "format": "date-time",
              },
              "wp_date": {
                "type": "string",
                "format": "date-time",
              },
              "title": {
                "type": "string",
                "format": "html-string",
              },
              "description": {
                "type": "string",
                "format": "html-string",
              },
              "wp_featured_media": {
                "type": "integer",
                "format": "int64",
              },
              "featuredMedia": {
                "$ref": "#/components/schemas/FeaturedMedia",
              },
              "link": {
                "type": "string",
                "format": "url",
              },
              "createdAt": {
                "type": "string",
                "format": "date-time",
              },
              "bgbs23": {
                "type": "boolean",
              },
              "updatedAt": {
                "type": "string",
                "format": "date-time",
              },
              "objectId": {
                "type": "string",
              },
            },
          },
          "Evento": {
            "type": "object",
            "description": "Success",
            "properties": {
              "wp_id": {
                "type": "integer",
                "format": "int64",
              },
              "wp_modified": {
                "type": "string",
                "format": "date-time",
              },
              "wp_date": {
                "type": "string",
                "format": "date-time",
              },
              "title": {
                "type": "string",
                "format": "html-string",
              },
              "description": {
                "type": "string",
                "format": "html-string",
              },
              "wp_featured_media": {
                "type": "integer",
                "format": "int64",
              },
              "featuredMedia": {
                "$ref": "#/components/schemas/FeaturedMedia",
              },
              "testo_intro_punti": {
                "type": "string",
              },
              "note_punti": {
                "type": "string",
              },
              "punti": {
                "type": "integer",
                "format": "int64",
              },
              "bgbs23": {
                "type": "boolean",
              },
              "link": {
                "type": "string",
                "format": "url",
              },
              "createdAt": {
                "type": "string",
                "format": "date-time",
              },
              "updatedAt": {
                "type": "string",
                "format": "date-time",
              },
              "wp_id_spazio_espositivo": {
                "type": "integer",
                "format": "int64",
              },
              "spazioEspositivo": {
                "$ref": "#/components/schemas/SpazioEspositivo",
              },
              "luogoSpettacolo": {
                "$ref": "#/components/schemas/LuogoSpettacolo",
              },
              "wp_id_mostra": {
                "type": "integer",
                "format": "int64",
              },
              "indirizzo": {
                "type": "string",
              },
              "cap": {
                "type": "string",
              },
              "comune": {
                "type": "string",
              },
              "provincia": {
                "type": "string",
              },
              "regione": {
                "type": "string",
                "enum": ["piemonte", "lombardia", "valle-d-aosta"],
              },
              "data_evento": {
                "type": "string",
                "format": "date",
                "example": "20220115",
              },
              "ora_evento": {
                "type": "string",
              },
              "orari": {
                "type": "array",
                "items": {
                  "type": "string",
                  "format": "time",
                },
              },
              "amclub": {
                "type": "boolean",
              },
              "objectId": {
                "type": "string",
              },
              "link_prenota": {
                "type": "string",
                "format": "url",
              },
              "testo_prenota": {
                "type": "string",
              },
            },
          },
          "Mostra": {
            "type": "object",
            "properties": {
              "wp_id": {
                "type": "integer",
                "format": "int64",
              },
              "wp_modified": {
                "type": "string",
                "format": "date-time",
              },
              "wp_date": {
                "type": "string",
                "format": "date-time",
              },
              "testo_intro_punti": {
                "type": "string",
              },
              "note_punti": {
                "type": "string",
              },
              "punti": {
                "type": "integer",
                "format": "int64",
              },
              "bgbs23": {
                "type": "boolean",
              },
              "link": {
                "type": "string",
                "format": "url",
              },
              "tipologia": {
                "type": "string",
                "enum": ["permanente", "temporanea"],
              },
              "title": {
                "type": "string",
                "format": "html-string",
              },
              "description": {
                "type": "string",
                "format": "html-string",
              },
              "scopri_di_piu": {
                "type": "string",
              },
              "createdAt": {
                "type": "string",
                "format": "date-time",
              },
              "updatedAt": {
                "type": "string",
                "format": "date-time",
              },
              "wp_featured_media": {
                "type": "integer",
                "format": "int64",
              },
              "featuredMedia": {
                "$ref": "#/components/schemas/FeaturedMedia",
              },
              "wp_id_spazio_espositivo": {
                "type": "integer",
                "format": "int64",
              },
              "spazioEspositivo": {
                "$ref": "#/components/schemas/SpazioEspositivo",
              },
              "objectId": {
                "type": "string",
              },
              "abbonamento": {
                "type": "string",
                "enum": [
                  "tessera-lombardia-valle-daosta",
                  "tessera-piemonte-valle-daosta",
                  "tessera-extra",
                ],
              },
              "ingresso": {
                "type": "string",
                "enum": ["gratuito", "scontato"],
              },
              "data_fine": {
                "type": "string",
                "format": "date",
                "example": "20220115",
              },
              "data_inizio": {
                "type": "string",
                "format": "date",
                "example": "20220116",
              },

              "orari": {
                "type": "string",
                "example":
                  "lunedi: chiuso\\nmartedi: chiuso\\nmercoledi: 10:00 - 13:00 | 14:00 - 19:00\\ngiovedi: 10:00 - 13:00 | 14:00 - 19:00\\nnvenerdi: 10:00 - 13:00 | 14:00 - 19:00\\nsabato: 10:00 - 20:00\\ndomenica: 10:00 - 20:00",
              },
              "aperture_chiusure_straordinarie": {
                "type": "string",
                "example": "dom 25.12.2022: chiuso\\ndom 01.01.2022: chiuso",
              },
            },
          },
          "FeaturedMedia": {
            "type": "object",
            "properties": {
              "wp_id": {
                "type": "integer",
                "format": "int64",
              },
              "wp_modified": {
                "type": "string",
                "format": "date-time",
              },
              "wp_date": {
                "type": "string",
                "format": "date-time",
              },
              "link": {
                "type": "string",
                "format": "url",
              },
              "createdAt": {
                "type": "string",
                "format": "date-time",
              },
              "updatedAt": {
                "type": "string",
                "format": "date-time",
              },
              "image_medium": {
                "type": "string",
                "format": "url",
              },
              "image_thumbnail": {
                "type": "string",
                "format": "url",
              },
              "mime_type": {
                "type": "string",
              },
              "image_full": {
                "type": "string",
                "format": "url",
              },
              "objectId": {
                "type": "string",
              },
            },
          },
          "LuogoSpettacolo": {
            "type": "object",
            "properties": {
              "wp_id": {
                "type": "integer",
                "format": "int64",
              },
              "wp_modified": {
                "type": "string",
                "format": "date-time",
              },
              "wp_date": {
                "type": "string",
                "format": "date-time",
              },
              // "bgbs23": {
              //   "type": "boolean",
              // },
              "link": {
                "type": "string",
                "format": "url",
              },
              "createdAt": {
                "type": "string",
                "format": "date-time",
              },
              "updatedAt": {
                "type": "string",
                "format": "date-time",
              },
              "title": {
                "type": "string",
                "format": "html-string",
              },
              "description": {
                "type": "string",
                "format": "html-string",
              },
              "objectId": {
                "type": "string",
              },
              "indirizzo": {
                "type": "string",
              },
              "cap": {
                "type": "string",
              },
              "comune": {
                "type": "string",
              },
              "provincia": {
                "type": "string",
              },
              "regione": {
                "type": "string",
                "enum": ["piemonte", "lombardia", "valle-d-aosta"],
              },
              "lat": {
                "type": "number",
                "format": "double",
              },
              "lng": {
                "type": "number",
                "format": "double",
              },
              "telefono": {
                "type": "string",
              },
              "sito_web": {
                "type": "string",
                "format": "url",
              },
              "categories": {
                "type": "array",
                "items": {
                  "type": "string",
                },
              },
            },
          },
          "Categoria": {
            "type": "object",
            "properties": {
              "wp_id": {
                "type": "integer",
                "format": "int32",
                "example": 2,
              },
              "link": {
                "type": "string",
                "example": "https://abbonamentomusei.it/category/archivio/",
              },
              "label": {
                "type": "string",
                "example": "Archivio",
              },
              "slug": {
                "type": "string",
                "example": "archivio",
              },
              "createdAt": {
                "type": "string",
                "example": "2023-03-10T17:25:49.486Z",
              },
              "updatedAt": {
                "type": "string",
                "example": "2023-03-10T17:25:49.486Z",
              },
              "objectId": {
                "type": "string",
                "example": "Hl4ND5fgcw",
              },
            },
          },
          "SpazioEspositivo": {
            "type": "object",
            "properties": {
              "wp_id": {
                "type": "integer",
                "format": "int64",
              },
              "wp_modified": {
                "type": "string",
                "format": "date-time",
              },
              "wp_date": {
                "type": "string",
                "format": "date-time",
              },
              "bgbs23": {
                "type": "boolean",
              },
              "mostra": {
                "$ref": "#/components/schemas/Mostra",
              },
              "link": {
                "type": "string",
                "format": "url",
              },
              "createdAt": {
                "type": "string",
                "format": "date-time",
              },
              "updatedAt": {
                "type": "string",
                "format": "date-time",
              },
              "title": {
                "type": "string",
                "format": "html-string",
              },
              "description": {
                "type": "string",
                "format": "html-string",
              },
              "objectId": {
                "type": "string",
              },
              "indirizzo": {
                "type": "string",
              },
              "cap": {
                "type": "string",
              },
              "comune": {
                "type": "string",
              },
              "provincia": {
                "type": "string",
              },
              "regione": {
                "type": "string",
                "enum": ["piemonte", "lombardia", "valle-d-aosta"],
              },
              "lat": {
                "type": "number",
                "format": "double",
              },
              "lng": {
                "type": "number",
                "format": "double",
              },
              "telefono": {
                "type": "string",
              },
              "sito_web": {
                "type": "string",
                "format": "url",
              },
              "fuori_circuito": {
                "type": "boolean",
              },
              "orari": {
                "type": "string",
                "example":
                  "lunedi: chiuso\\nmartedi: chiuso\\nmercoledi: 10:00 - 13:00 | 14:00 - 19:00\\ngiovedi: 10:00 - 13:00 | 14:00 - 19:00\\nnvenerdi: 10:00 - 13:00 | 14:00 - 19:00\\nsabato: 10:00 - 20:00\\ndomenica: 10:00 - 20:00",
              },
              "aperture_chiusure_straordinarie": {
                "type": "string",
                "example": "dom 25.12.2022: chiuso\\ndom 01.01.2022: chiuso",
              },
              "categories": {
                "type": "array",
                "items": {
                  "type": "string",
                },
              },
            },
          },
          "ArrayOfNotizia": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Notizia",
            },
          },
          "ArrayOfCategories": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Categoria",
            },
          },
          "ArrayOfLuogoSpettacolo": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/LuogoSpettacolo",
            },
          },
          "ArrayOfEvento": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Evento",
            },
          },
          "ArrayOfMostra": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Mostra",
            },
          },
          "ArrayOfSpazioEspositivo": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/SpazioEspositivo",
            },
          },
          "Error": {
            "type": "object",
            "properties": {
              "code": {
                "type": "integer",
              },
              "message": {
                "type": "string",
              },
            },
            "required": ["code", "message"],
          },
        },
        "responses": {
          "NotFound": {
            "description": "The specified resource was not found",
            "content": {
              "application/json": {
                "example": {
                  "code": 404,
                  "message": "Not Found",
                },
                "schema": {
                  "$ref": "#/components/schemas/Error",
                  "code": 404,
                  "message": "Not Found",
                },
              },
            },
          },
          "Unauthorized": {
            "description": "Unauthorized",
            "content": {
              "application/json": {
                "example": {
                  "code": 401,
                  "message": "Unauthorized",
                },
                "schema": {
                  "$ref": "#/components/schemas/Error",
                },
              },
            },
          },
          "Forbidden": {
            "description": "Forbidden",
            "content": {
              "application/json": {
                "example": {
                  "code": 403,
                  "message": "Unauthorized",
                },
                "schema": {
                  "$ref": "#/components/schemas/Error",
                },
              },
            },
          },
          "BadRequest": {
            "description": "Bad Request",
            "content": {
              "application/json": {
                "example": {
                  "code": 400,
                  "message": "Bad Request",
                },
                "schema": {
                  "$ref": "#/components/schemas/Error",
                },
              },
            },
          },
          "RequestTimeout": {
            "description": "Request Timeout",
            "content": {
              "application/json": {
                "example": {
                  "code": 408,
                  "message": "Request Timeout",
                },
                "schema": {
                  "$ref": "#/components/schemas/Error",
                },
              },
            },
          },
          "InternalServerError": {
            "description": "Internal Server Error",
            "content": {
              "application/json": {
                "example": {
                  "code": 500,
                  "message": "Internal Server Error",
                },
                "schema": {
                  "$ref": "#/components/schemas/Error",
                },
              },
            },
          },
          "ServiceUnavailable": {
            "description": "Service Unavailable",
            "content": {
              "application/json": {
                "example": {
                  "code": 502,
                  "message": "Service Unavailable",
                },
                "schema": {
                  "$ref": "#/components/schemas/Error",
                },
              },
            },
          },
        },
      },
    }}
  />
);
export default App;
